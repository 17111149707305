import zilvercms from "./_utils";

const headerNav = document.querySelector('.js-header-nav');
const headerNavTriggers = [...document.querySelectorAll('.js-header-nav-trigger')];

if(headerNav && headerNavTriggers.length > 0 && zilvercms.viewport.isEqualOrSmallerThan('md')) {
    headerNavTriggers.map(headerNavTrigger => headerNavTrigger.addEventListener('click', e => {
        e.preventDefault();

        if(headerNav.hasAttribute('data-open')) {
            headerNav.removeAttribute('data-open');
        } else {
            headerNav.setAttribute('data-open', '');
        }
    }));
}
