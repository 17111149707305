export const set = (name, value, days) => {
    let expires = "";

    if(days) {
        const date = new Date();

        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

        expires = "; expires="+ date.toUTCString();
    }

    document.cookie = `${name}=${value}${expires}; path=/${!window.location.host.includes('10') ? ';secure' : ''}`;
};

export const get = name => {
    const nameEQ = name +"=";
    let ca = document.cookie.split(';');

    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while(c.charAt(0) === ' '){
            c = c.substring(1, c.length);
        }

        if(c.indexOf(nameEQ) === 0){
            return c.substring(nameEQ.length, c.length);
        }
    }

    return null;
};

export const remove = name => {
    document.cookie = name +'=; Max-Age=-99999999;';
};

export default {set, get, remove};
