import zilvercms from "./_utils";

if(zilvercms.viewport.isEqualOrSmallerThan('md')) {
    const footerBlocks = [...document.querySelectorAll('.js-footer-block')];

    footerBlocks.map(footerBlock => {
        const heading = footerBlock.querySelector('.js-footer-block-heading');
        const content = footerBlock.querySelector('.js-footer-block-content');

        heading.addEventListener('click', () => {
            if(footerBlock.hasAttribute('data-open')) {
                footerBlock.removeAttribute('data-open');
                content.style.height = 0;
            } else {
                footerBlock.setAttribute('data-open', '');
                content.style.height = `${content.scrollHeight}px`;
            }
        });
    });

}
