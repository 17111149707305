const zilvercms = {};

zilvercms.getMetaValue = key => {
    const metaTag = document.querySelector('meta[name="zilvercms:'+ key +'"]');

    if(metaTag !== null) {
        return metaTag.getAttribute('content');
    }

    return '';
};

zilvercms.variables = {
    cdnPrefix: zilvercms.getMetaValue('cdnPrefix'),
    currency: {
        showSymbol: !!parseInt(zilvercms.getMetaValue('currencyShowSymbol')),
        symbol: zilvercms.getMetaValue('currencySymbol'),
        leadingSymbol: !!parseInt(zilvercms.getMetaValue('currencyLeadingSymbol')),
        separator: zilvercms.getMetaValue('currencySeparator'),
        zerosAsDash: zilvercms.getMetaValue('currencyZerosAsDash'),
        withoutZeros: !!parseInt(zilvercms.getMetaValue('currencyWithoutZeros')),
    },
    sizes: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
};

zilvercms.imageHelper = (url, options, quality) => {
    url = !!url ? url : '';
    options = !!options ? options : '100x100';
    quality = !!quality ? quality : 80;

    let prefix = zilvercms.variables.cdnPrefix;
    prefix = prefix.replace('###', options);
    prefix = prefix.replace('QQQ', quality);

    return prefix + url;
};

zilvercms.currencyHelper = amount => {
    let value = (amount / 100).toFixed(2);
    const separator = zilvercms.variables.currency.separator;

    value = value.replace('.', separator);

    if(zilvercms.variables.currency.showSymbol) {
        const symbol = zilvercms.variables.currency.symbol;

        value = zilvercms.variables.currency.leadingSymbol ? symbol + ' ' + value : value + ' ' + symbol;
    }

    if(zilvercms.variables.currency.zerosAsDash === 1) {
        value = value.replace((separator + '00'), (separator + '-'));
    } else if(zilvercms.variables.currency.withoutZeros) {
        value = value.replace((separator + '00'), '');
    }

    return value;
};

zilvercms.translationHelper = (key, variables) => {
    if(typeof TRANSLATIONS !== 'undefined' && typeof TRANSLATIONS[key] !== 'undefined') {
        let translation = TRANSLATIONS[key];

        if(typeof variables === 'object') {
            for(const variable in variables) {
                translation = translation.replace(variable, variables[variable]);
            }
        }

        return translation;
    }

    return key;
};

zilvercms.serialize = array => {
    return array.map(value => ([value[0], value[1]].join('='))).join('&');
};

zilvercms.lockBody = () => {
    const scrollTop = window.scrollY;

    document.body.classList.add('locked');

    if(scrollTop === 0) {
        document.body.classList.add('locked--on-top');
    }

    if(document.documentElement.scrollHeight > document.documentElement.clientHeight) {
        document.body.style.overflowY = 'scroll';
    }

    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.setProperty('margin-top', `-${scrollTop}px`, 'important');

    document.body.dataset.scrollTop = scrollTop;
};

zilvercms.unlockBody = () => {
    document.body.classList.remove('locked', 'locked--on-top');

    document.body.style.removeProperty('position');
    document.body.style.removeProperty('width');
    document.body.style.removeProperty('overflow-y');
    document.body.style.removeProperty('margin-top');

    window.scrollTo({top: document.body.dataset.scrollTop});
    document.body.removeAttribute('data-scroll-top');
};

zilvercms.viewport = {
    is: () => window.getComputedStyle(document.body, ':after').content.replace(/"/g, ''),
    isEqualOrGreaterThan: size => zilvercms.viewport.getIndex(zilvercms.viewport.is()) >= zilvercms.viewport.getIndex(size),
    isGreaterThan: size => zilvercms.viewport.getIndex(zilvercms.viewport.is()) > zilvercms.viewport.getIndex(size),
    isEqualOrSmallerThan: size => zilvercms.viewport.getIndex(zilvercms.viewport.is()) <= zilvercms.viewport.getIndex(size),
    isSmallerThan: size => zilvercms.viewport.getIndex(zilvercms.viewport.is()) < zilvercms.viewport.getIndex(size),
    getIndex: size => {
        const number = zilvercms.variables.sizes.indexOf(size);
        if (number === -1) throw 'no valid viewport name given';
        return number;
    }
};

export default zilvercms;
