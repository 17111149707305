const Cookie = require('./_cookie');
const search = document.querySelector('.js-search');

if(![null, undefined].includes(search)) {
    const zipcodeElement = search.querySelector('.js-search-zipcode');
    const distanceElement = search.querySelector('.js-search-distance');

    let zipcode = zipcodeElement.value;
    let distance = parseInt(distanceElement.value);

    const searchCookie = JSON.parse(Cookie.get('search'));

    if(zipcode === '' && ![null, undefined, ''].includes(searchCookie?.zipcode)) {
        zipcode = searchCookie.zipcode;
        zipcodeElement.value = searchCookie.zipcode;
    }

    if(distance === 0 && !window.location.search.includes('distance=') && ![null, undefined, ''].includes(searchCookie?.distance)) {
        distance = searchCookie.distance;
        distanceElement.value = searchCookie.distance;
    }

    if(zipcode !== '') {
        Cookie.set('search', JSON.stringify({zipcode, distance}));
    }

    distanceElement.addEventListener('change', () => search.submit());
}
