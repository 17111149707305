// Base stylesheet needed for every page
import './scss/app.scss';

// Lazy load page depended scripts and stylesheets method
const loadComponent = async name => {
    const {default: component} = await import(/* webpackPreload: true */ `./${name}.js`);

    return component();
}

// Import everything in directory method used for example for images
const importAll = (r) => r.keys().forEach(r);

require('./scripts/footer-blocks');
require('./scripts/header-nav');
require('./scripts/logout');
require('./scripts/search');

// Include page depended scripts and styles
if(document.querySelector('.js-components') !== null) {
    loadComponent('components');
} else if(document.querySelector('.js-productlist') !== null) {
    loadComponent('productlist');
} else if(document.querySelector('.js-productdetails') !== null) {
    loadComponent('productdetails');
} else if(document.querySelector('.js-success') !== null) {
    loadComponent('success');
} else if(document.querySelector('.js-customer') !== null) {
    loadComponent('customer');

    if(document.querySelector('.js-register') !== null) {
        loadComponent('register');
    }
}

// Importing all images
const images = require.context('./images', true,  /\.(png|jpe?g|gif)$/);
importAll(images);
